.CheckoutSummary {
    text-align: center;
    width: 80%;
    margin: auto;
}


@media (min-width: 600) {
    .CheckoutSummary {
        width: 500px;
    }
}